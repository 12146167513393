import {
    Grid,
    Container,
} from '@mui/material';
import { Fragment } from 'react';
import AudioBars from './AudioBars';
import LeftDetailsBox from './LeftDetailsBox';
import RightDetailsBox from './RightDetailsBox';
import Sponsors from './Sponsors';
import LogoRow from './LogoRow';
import CustomSlider from './CustomSlider';

function LandingPage() {
    return  <Fragment>
        <Container
            sx={{
                'paddingTop': '70px',
            }}
        >
            <Grid container>
                <LogoRow/>
                <LeftDetailsBox/>
                <RightDetailsBox/>
                <AudioBars/>
                <CustomSlider/>
            </Grid>
        </Container>
        <Sponsors/>
    </Fragment>;
}

export default LandingPage;