import React from 'react';
import { Box } from '@mui/material';
import InfoBox from './InfoBox';

function InfoBoxesContainer() {
    return (
        <Box sx={{
            'display': 'flex',
            'flexDirection': { 'xs': 'column', 'sm': 'row' },
            'alignItems': { 'xs': 'center', 'sm': 'flex-start' },
            'justifyContent': {
                'xs': 'center',
                'sm': 'left'
            },
        }}>
            <InfoBox>
                When<br/><br/><b>March 16, 2025</b>
            </InfoBox>
            <InfoBox>
                Where<br/><br/><b>San Francisco, CA</b>
            </InfoBox>
        </Box>
    );
}

export default InfoBoxesContainer;