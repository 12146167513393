import { Grid, Typography } from '@mui/material';
import InfoBoxesContainer from './InfoBoxesContainer';

function LeftDetailsBox() {    
    return (
        <Grid item 
            xs={ 12 }
            md={ 6 }
            style={{
                    'color': '#FFFFFF',
            }}
            paddingRight={{
                'md': '15px'
            }}
            marginTop='70px'
        >
            <Typography 
                component='div'
                variant='h1'
                style={{
                    'fontSize': '40px',
                    'fontWeight': '300',
                    'lineHeight': '54px',
                    'textTransform': 'uppercase',
                    'marginBottom': '30px',
                }}
            >
                Get GDC 2025 tickets + support a local charity
            </Typography>
            <Typography 
                component='div'
                variant='body1'
                marginBottom={{
                    'xs': '42px',
                    'lg': '22px',
                }}
            >
                The indie giving event is a special program designed to help game developers attend 
                the 2025 Game Developer Conference in San Francisco as well as contribute to local 
                charitable organizations. Everyone who signs up will get the chance to take part in 
                a charity event to support <a style={{'color': '#fff'}} href='https://www.compass-sf.org/'>Compass 
                Family Services</a>.
                <br />
                Disclaimer: Attendance at the Indie Giving charity event is required to receive GDC pass
            </Typography>
            <InfoBoxesContainer/>
        </Grid>
    );
}

export default LeftDetailsBox;

